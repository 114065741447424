<template>
  <v-row>
    <v-col>
      <v-card color="grey lighten-4" elevation="1">
        <v-card-text>
          <v-row class="text-caption align-center">
            <v-col cols="12" sm="auto">
              <span class="font-weight-bold">Código interno:</span
              ><span class="ml-2">{{ formData.code || "-" }}</span>
            </v-col>
            <v-col cols="12" sm="auto">
              <span class="font-weight-bold">Sesiones:</span
              ><span class="ml-2">{{ formData.sessionsQuantity || 0 }}</span>
            </v-col>
            <v-col cols="12" sm="auto">
              <span class="font-weight-bold">Participantes:</span
              ><span class="ml-2">{{ formData.inscriptionsQuantity || 0 }}</span>
            </v-col>
            <v-col cols="12" sm="auto">
              <span class="font-weight-bold">Fecha creación:</span
              ><span class="ml-2">{{ $moment(formData.date_created).format("L") }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="formData.notApprovedStudents" cols="12" sm="auto">
              <v-chip small text-color="error">
                <span class="font-weight-bold">No aprobados:</span
                ><span class="ml-2">{{ formData.notApprovedStudents }}</span>
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    formData: { type: Object, required: true },
  },
  methods: {
    getExpiryDateColor(date) {
      if (!date) return "grey";
    },
  },
};
</script>

<style scoped></style>
